<template lang="pug">
.ds-dropdown
  PaneLayout(:layout="layout" gap="4px" :gridLayout="gridLayout")
    template(#left)
      .d-flex.align-items-center(v-if="label")
        .ds-dropdown-label(v-html="label")
        DeviceSelector(v-if="deviceSelector && editMobile" :hasViewText="false")
    template(#right)
      .d-flex.align-items-center
        om-select.w-100(
          :options="items"
          size="small"
          :disabled="disabled"
          :error="error"
          :errorMessage="errorMessage"
          :id="id"
          v-model="internalValue"
          optionText="text"
          optionValue="value"
          optionKey="value"
          :searchable="searchable"
          :fontOptions="fontOptions"
        )
        slot(name="afterDropdown")
    template(#help)
      template(v-if="error && errorMessage && layout === 'col'")
        .ds-dropdown-error-message {{ errorMessage }}
      .ds-dropdown-help-text.mt-1
        slot(name="help")
  slot
</template>

<script>
  import designSystemMixin from '@/components/Elements/mixins/designSystem';
  import { OmSelect } from '@/components/Elements';

  export default {
    name: 'Dropdown',
    components: {
      OmSelect,
      PaneLayout: () => import('../PaneLayout.vue'),
      DeviceSelector: () => import('@/editor/components/sidebar/components/DeviceSelector.vue'),
    },
    mixins: [designSystemMixin],
    props: {
      label: { type: String, required: false },
      items: { type: Array, default: () => [], required: true },
      value: { type: [String, Number, Object], default: () => null },
      editMobile: { type: Boolean, default: false },
      deviceSelector: { type: Boolean, default: true },
      layout: {
        type: String,
        default: 'row',
        options: ['row', 'col'],
        validator: (value) => {
          return ['row', 'col'].includes(value);
        },
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      error: {
        type: Boolean,
        default: false,
      },
      errorMessage: {
        type: String,
        default: '',
      },
      id: {
        type: String,
        required: true,
      },
      searchable: {
        type: Boolean,
        default: false,
      },
      fontOptions: {
        type: Boolean,
        default: false,
      },
      default: {
        type: [String, Object, Array, Number],
        default() {
          return null;
        },
      },
      gridLayout: {
        type: String,
        default: () => null,
      },
    },
    computed: {
      internalValue: {
        get() {
          return this.items.find(({ value }) => this.value === value);
        },
        set(selected) {
          const value = selected?.value ?? this.default;
          this.$emit('input', value);
        },
      },
    },
    methods: {},
  };
</script>

<style lang="sass">
  @import 'dropdown.sass'
</style>
